export const config = {
    RPC_URL: 'https://rpc-akash-ia.cosmosia.notional.ventures:443',
    REST_URL: 'https://api-akash-ia.cosmosia.notional.ventures',
    EXPLORER_URL: 'https://www.mintscan.io/akash',
    STAKING_URL: 'https://akash.omniflix.co/stake',
    NETWORK_NAME: 'Akash',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'akashnet-2',
    CHAIN_NAME: 'Akash Network',
    COIN_DENOM: 'AKT',
    COIN_MINIMAL_DENOM: 'uakt',
    COIN_DECIMALS: 6,
    PREFIX: 'akash',
    COIN_TYPE: 118,
    COSMOSTAION: 'akash',
    COINGECKO_ID: 'akash-network',
    GAS_PRICE_STEP_LOW: 0.01,
    GAS_PRICE_STEP_AVERAGE: 0.025,
    GAS_PRICE_STEP_HIGH: 0.04,
    FEATURES: ['ibc-transfer'],
};
